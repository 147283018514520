import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { use, useEffect } from "react";
import { useGetBookingsQuery } from "../booking/apiSlice";

export default function SuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bookingData = {
    id: "123456",
    guestName: "Thinley",
    checkInDate: "March 10, 2025",
    checkOutDate: "March 15, 2025",
    roomType: "Deluxe Suite",
    totalPrice: 1900,
  };
  const { data, error, isLoading } = useGetBookingsQuery({
    orderNo: searchParams.get("orderNo"),
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div
      className="min-h-screen flex items-center justify-center p-4"
      style={{
        backgroundImage: "url('/images/general/booking.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      {console.log(data)}
      <div className="bg-gray-100 shadow-2xl rounded-3xl p-8 max-w-md w-full text-center transform transition-all hover:scale-105">
        <div className="flex justify-center">
          <CheckCircleIcon className="w-20 h-20 text-green-500" />
        </div>

        <h1 className="text-3xl font-bold text-green-600 mt-4">
          Booking Confirmed! 🎉
        </h1>
        <p className="text-gray-600 mt-2 text-lg">
          Your reservation has been successfully completed.
        </p>

        <div className="mt-6 p-5 bg-white rounded-xl shadow-md">
          <p className="text-lg font-semibold text-gray-800">
            Booking Details:
          </p>
          <div className="text-left mt-3 text-gray-700 space-y-2">
            <p>
              <span className="font-medium">📌 Booking ID:</span> {data.orderNo}
            </p>
            <p>
              <span className="font-medium">👤 Guest Name:</span> {data.name}
            </p>
            <p>
              <span className="font-medium">📅 Check-in:</span>{" "}
              {data.checkinDate}
            </p>
            <p>
              <span className="font-medium">🏨 Check-out:</span>{" "}
              {data.checkoutDate}
            </p>
            <p>
              <span className="font-medium">🛏 Room Type:</span>{" "}
              {bookingData.roomType}
            </p>
            <p>
              <span className="font-medium">💰 Paid Amount:</span> Nu.
              {data.paidAmount}
            </p>
            <p>
              <span className="font-medium">💰 Full Payment</span>
              {data.fullPayment ? "True" : "False"}
            </p>
            <p>
              <span className="font-medium">💰 Total Price:</span> Nu.
              {data.totalAmount}
            </p>
          </div>
        </div>

        <div className="mt-6 flex flex-col gap-3">
          <button
            className="w-full bg-gray-800 hover:bg-gray-900 text-white py-3 rounded-xl font-semibold transition-transform transform hover:scale-105"
            onClick={() => navigate("/")}
          >
            Go to Homepage
          </button>
          <button
            className="w-full bg-[#E0B973] text-white py-3 rounded-xl font-semibold transition-transform transform hover:scale-105"
            onClick={() => navigate("/booking")}
          >
            Make Another Booking
          </button>
        </div>
      </div>
    </div>
  );
}
