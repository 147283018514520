import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function CancelPage() {
  const navigate = useNavigate();

  return (
    <div
      className="min-h-screen flex items-center justify-center p-4"
      style={{
        backgroundImage: "url('/images/general/booking.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <div className="bg-gray-100 shadow-2xl rounded-3xl p-8 max-w-md w-full text-center transform transition-all hover:scale-105">
        <div className="flex justify-center">
          <ExclamationCircleIcon className="w-20 h-20 text-yellow-500 " />
        </div>

        <h1 className="text-3xl font-bold text-yellow-600 mt-4">
          Booking Canceled ⚠️
        </h1>
        <p className="text-gray-600 mt-2 text-lg">
          Your reservation has been canceled successfully.
        </p>

        <div className="mt-6 flex flex-col gap-3">
          <button
            className="w-full bg-gray-800 hover:bg-gray-900 text-white py-3 rounded-xl font-semibold transition-transform transform hover:scale-105"
            onClick={() => navigate("/")}
          >
            Go to Homepage
          </button>
          <button
            className="w-full bg-[#E0B973] text-white py-3 rounded-xl font-semibold transition-transform transform hover:scale-105"
            onClick={() => navigate("/booking")}
          >
            Book Again
          </button>
        </div>
      </div>
    </div>
  );
}
