import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../../auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://zeebar-api.onrender.com",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);

    if (refreshResult?.data) {
      api.dispatch(setCredentials({ ...refreshResult.data }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = "your login has expired";
      }
      return refreshResult;
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "https://zeebar-api.onrender.com" }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:5000" }),
  endpoints: (builder) => ({
    makeBooking: builder.mutation({
      query: (payload) => ({
        url: "/booking/book",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["booking"],
    }),
    getBookings: builder.query({
      query: ({ orderNo }) => ({
        url: `/booking`,
        params: { orderNo },
      }),
    }),
    getAvailableRooms: builder.query({
      query: (params) => ({
        url: `/booking/available/`,
        method: "GET",
        params,
      }),
    }),
    sendMessage: builder.mutation({
      query: (payload) => ({
        url: "/contact",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["message"],
    }),
  }),
});

export const {
  useMakeBookingMutation,
  useGetBookingsQuery,
  useLazyGetAvailableRoomsQuery,
  useSendMessageMutation,
} = apiSlice;
